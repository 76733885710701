import * as S from './styles';

type Props = { onClick: () => void; icon?: JSX.Element; text: string };

const NavBarButton = ({ onClick, text, icon }: Props) => {
  return (
    <S.Button onClick={onClick}>
      {icon}
      {text}
    </S.Button>
  );
};

export default NavBarButton;
