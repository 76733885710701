import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import createStore from '@pressbox-video/redux-store/Store';
import TranslationProvider from '@pressbox-video/ui-translations/components/TranslationProvider';
import NotificationProvider from '@pressbox-video/ui-components/notification-provider';

import { environment } from '../environments/environment';
import withUserLanguage from './hocs/withUserLanguage';
import UserLoader from './components/user-loader';
import Routes from './routes';
import SectionLockProvider from './components/SectionLock/SectionLockProvider';
import ExistenceOfChangesProvider from './components/ExistenceOfChangesModal/ExistenceOfChangesProvider';
import ProtectedRoute from './components/ProtectedRoute';

// TODO: remove after creating a generic getEnvVar utility that will throw error if there's missing env
if (!environment.NX_ADMIN_AUTH0_DOMAIN_NAME || !environment.NX_ADMIN_AUTH0_CLIENT_ID) {
  throw Error('Auth0 config not specified');
}

const store = createStore();
const TranslationsWithUserLanguage = withUserLanguage(TranslationProvider);

export function App() {
  return (
    <Auth0Provider
      redirectUri={window.location.origin}
      domain={environment.NX_ADMIN_AUTH0_DOMAIN_NAME}
      clientId={environment.NX_ADMIN_AUTH0_CLIENT_ID}
      audience={environment.NX_ADMIN_AUTH0_AUDIENCE}
      useRefreshTokens={true}>
      <Provider store={store}>
        <BrowserRouter>
          <TranslationsWithUserLanguage>
            <UserLoader>
              <NotificationProvider>
                <ProtectedRoute>
                  <ExistenceOfChangesProvider>
                    <SectionLockProvider>
                      <Routes />
                    </SectionLockProvider>
                  </ExistenceOfChangesProvider>
                </ProtectedRoute>
              </NotificationProvider>
            </UserLoader>
          </TranslationsWithUserLanguage>
        </BrowserRouter>
      </Provider>
    </Auth0Provider>
  );
}

export default App;
