import React, { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';

import { i18nInstance } from '../../i18next';
import { LanguageIsoCodes } from '../../constants';
import { changeAppLanguage, getBrowserLocale } from '../../utils';

type Props = {
  userLanguage?: string;
  children: React.ReactNode;
};

const TranslationProvider = ({ children, userLanguage = '' }: Props): JSX.Element => {
  const languageIsoCodeFromAuth0 =
    userLanguage && LanguageIsoCodes[userLanguage.split('-')[0] as keyof typeof LanguageIsoCodes];

  useEffect(() => {
    const setInitialLanguage = async () => {
      const languageSetting =
        languageIsoCodeFromAuth0 ||
        LanguageIsoCodes[getBrowserLocale().split(/[-_]/)[0] as keyof typeof LanguageIsoCodes] ||
        LanguageIsoCodes.en;

      await changeAppLanguage(languageSetting);
    };

    setInitialLanguage();
  }, [userLanguage]);

  return <I18nextProvider i18n={i18nInstance}>{children}</I18nextProvider>;
};

export default TranslationProvider;
