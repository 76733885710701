import styled from 'styled-components';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const NavBarButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;

export const ExitIcon = styled(NavBarButtonIcon).attrs({ icon: faSignOutAlt })``;

export const Email = styled.span`
  color: white;
`;
