import { Modal } from 'reactstrap';

import Button from '@pressbox-video/ui-components/playbook/Button';

type Props = {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

const ExistenceOfChangesModal = ({ isOpen, onConfirm, onCancel }: Props) => {
  return (
    <Modal isOpen={isOpen}>
      <div className='modal-wrapper warning-modal'>
        <p className='modal-title'>Leave without applying changes?</p>
        <p className='modal-subtitle'>Changes made to the admin console will not be saved.</p>
        <div className='d-flex justify-content-between mt-5'>
          <Button onClick={onConfirm}>Yes, leave</Button>
          <button className='modal-cancel' onClick={onCancel}>
            No, stay
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ExistenceOfChangesModal;
