import React from 'react';
// eslint-disable-next-line
//@ts-ignore
import { NotificationProps, Notification as BaseNotification } from '@as/playbook-components';

export type NotificationVariant = NotificationProps['variant'];

export const Notification = (props: NotificationProps): JSX.Element => {
  return <BaseNotification {...props} />;
};
