import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Loader from '@pressbox-video/ui-components/loader/loader';
import { UserService } from '../../services/user.service';
import { SETUSER, SET_EMAIL } from '@pressbox-video/redux-store/constants';
import { APIService } from '../../services/api.service';

type Props = {
  children: React.ReactNode;
};

const UserLoader: ({ children }: Props) => JSX.Element = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const auth0 = useAuth0();
  const [isUserLoading, setUserLoading] = useState(true);

  const isUserSigningOut = () => {
    return history.location.pathname === '/sign-out';
  };

  const getUser = async (email: string) => {
    const user = await UserService.getUserByEmail(email);

    if (!user) {
      throw new Error('No user was found.');
    }

    dispatch({
      type: SETUSER,
      payload: { user },
    });

    dispatch({
      type: SET_EMAIL,
      email: user.user?.email,
    });
  };

  const refetchToken = async () => {
    console.log('get auth token');

    await auth0.getAccessTokenSilently({ ignoreCache: true }).catch(console.error);
  };

  useEffect(() => {
    APIService.setAuth0(auth0);
  }, []);

  useEffect(() => {
    (async () => {
      if (auth0.isLoading || isUserSigningOut()) {
        return;
      }

      if (auth0.isAuthenticated && auth0.user?.email) {
        setUserLoading(true);
        try {
          await getUser(auth0.user.email);
        } catch (e) {
          console.log(e);
          console.error('An error occurred while loading user');
        }
      }

      setUserLoading(false);
    })();
  }, [auth0.isAuthenticated, auth0.isLoading]);

  useEffect(() => {
    if (auth0.isLoading || auth0.isAuthenticated || isUserSigningOut()) {
      return;
    }

    refetchToken();
  }, [location.pathname, auth0.isLoading, auth0.isAuthenticated]);

  return (auth0.isLoading || isUserLoading) && !isUserSigningOut() ? <Loader /> : <>{children}</>;
};

export default UserLoader;
