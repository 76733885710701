import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
};

const ProtectedRoute = ({ children }: Props): JSX.Element | null => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  if (!isAuthenticated && searchParams.get('error') === 'access_denied') {
    return <div>Required role is missing to access to the admin console.</div>;
  }

  if (!isAuthenticated && searchParams.get('error')) {
    return <div>{searchParams.get('error_description')}</div>;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return null;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
