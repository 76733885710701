import { IPricingEngine } from '../interfaces/admin.interface';

export const CREDIT_PACKAGE_EMPTY_ROW = {
  id: 0,
  credits: '',
  cost: '',
  multiplier: '',
  videosCount: '',
  isDeleted: false,
  isPreSelected: false,
  tier: [],
};

export const ADMIN_CONSOLE = {
  MAX_CREDIT_OVERRIDE: 100000,
};
export const MAX_CREDIT_PACKAGES_PER_TIER = 6;
export const ADMIN_CONSOLE_CONTENT_PAGE_SIZE = 10;
export const TIER_TV_MARKET = ['National', 'Cable', 'Public Access'];
export const TIER_COLUMN_NAMES = ['', 'Tier 1', 'Tier 2', 'Tier 3', 'Tier 4', 'Auto Tier'];

export const PRICING_ENGINE_EMPTY_STATE = (): IPricingEngine => ({
  platformAttributes: [
    {
      id: 1,
      platformAttribute: 'digital',
      platformAttributeDisplayName: 'Digital',
      subAttributes: [
        {
          id: 1,
          platformAttributeId: 1,
          platformSubAttribute: 'site_rank',
          platformSubAttributeDisplayName: 'Site Rank',
          defaultTier: undefined,
          platformTierOnCount: {
            id: 1,
            platformSubAttributeId: 1,
            tier1: undefined,
            tier2: undefined,
            tier3: undefined,
            tier4: undefined,
          },
          platformTierOnValue: undefined,
        },
        {
          id: 2,
          platformAttributeId: 1,
          platformSubAttribute: 'betting',
          platformSubAttributeDisplayName: 'Betting',
          defaultTier: undefined,
          platformTierOnCount: undefined,
          platformTierOnValue: undefined,
        },
      ],
    },
    {
      id: 3,
      platformAttribute: 'tv',
      platformAttributeDisplayName: 'TV',
      subAttributes: [
        {
          id: 5,
          platformAttributeId: 3,
          platformSubAttribute: 'market',
          platformSubAttributeDisplayName: 'Market',
          defaultTier: undefined,
          platformTierOnCount: undefined,
          platformTierOnValue: {
            id: 1,
            platformSubAttributeId: 5,
            tier1: undefined,
            tier2: undefined,
            tier3: undefined,
            tier4: undefined,
          },
        },
        {
          id: 6,
          platformAttributeId: 3,
          platformSubAttribute: 'ott',
          platformSubAttributeDisplayName: 'OTT',
          defaultTier: undefined,
          platformTierOnCount: undefined,
          platformTierOnValue: undefined,
        },
      ],
    },
    {
      id: 2,
      platformAttribute: 'social',
      platformAttributeDisplayName: 'Social',
      subAttributes: [
        {
          id: 3,
          platformAttributeId: 2,
          platformSubAttribute: 'facebook',
          platformSubAttributeDisplayName: 'Facebook',
          defaultTier: undefined,
          platformTierOnCount: {
            id: 2,
            platformSubAttributeId: 3,
            tier1: undefined,
            tier2: undefined,
            tier3: undefined,
            tier4: undefined,
          },
          platformTierOnValue: undefined,
        },
        {
          id: 4,
          platformAttributeId: 2,
          platformSubAttribute: 'twitter',
          platformSubAttributeDisplayName: 'Twitter',
          defaultTier: undefined,
          platformTierOnCount: {
            id: 3,
            platformSubAttributeId: 4,
            tier1: undefined,
            tier2: undefined,
            tier3: undefined,
            tier4: undefined,
          },
          platformTierOnValue: undefined,
        },
        {
          id: 7,
          platformAttributeId: 2,
          platformSubAttribute: 'tiktok',
          platformSubAttributeDisplayName: 'TikTok',
          defaultTier: undefined,
          platformTierOnCount: {
            id: 4,
            platformSubAttributeId: 7,
            tier1: undefined,
            tier2: undefined,
            tier3: undefined,
            tier4: undefined,
          },
          platformTierOnValue: undefined,
        },
      ],
    },
  ],
  regionAttributes: [
    {
      id: 1,
      regionCode: 'usa',
      regionName: 'USA',
      regionFactor: undefined,
    },
    {
      id: 2,
      regionCode: 'uk',
      regionName: 'UK',
      regionFactor: undefined,
    },
    {
      id: 3,
      regionCode: 'can',
      regionName: 'CAN',
      regionFactor: undefined,
    },
    {
      id: 4,
      regionCode: 'aus',
      regionName: 'AUS',
      regionFactor: undefined,
    },
    {
      id: 5,
      regionCode: 'nez',
      regionName: 'NEZ',
      regionFactor: undefined,
    },
    {
      id: 6,
      regionCode: 'neurope',
      regionName: 'NEUROPE',
      regionFactor: undefined,
    },
    {
      id: 7,
      regionCode: 'ceurope',
      regionName: 'CEUROPE',
      regionFactor: undefined,
    },
    {
      id: 8,
      regionCode: 'seurope',
      regionName: 'SEUROPE',
      regionFactor: undefined,
    },
    {
      id: 9,
      regionCode: 'asia',
      regionName: 'ASIA',
      regionFactor: undefined,
    },
    {
      id: 10,
      regionCode: 'me',
      regionName: 'ME',
      regionFactor: undefined,
    },
    {
      id: 11,
      regionCode: 'latam',
      regionName: 'LATAM',
      regionFactor: undefined,
    },
    {
      id: 12,
      regionCode: 'africa',
      regionName: 'AFRICA',
      regionFactor: undefined,
    },
    {
      id: 13,
      regionCode: 'blocked',
      regionName: 'BLOCKED',
      regionFactor: 0,
    },
    {
      id: 14,
      regionCode: 'china',
      regionName: 'CHINA',
      regionFactor: undefined,
    },
    {
      id: 15,
      regionCode: 'hong_kong',
      regionName: 'HONG KONG',
      regionFactor: undefined,
    },
    {
      id: 16,
      regionCode: 'japan',
      regionName: 'JAPAN',
      regionFactor: undefined,
    },
  ],
  activityFactors: [
    {
      id: 1,
      threshold: undefined,
      thresholdType: 'high',
      thresholdName: 'High',
      factor: undefined,
      activityPercentage: undefined,
    },
    {
      id: 2,
      threshold: undefined,
      thresholdType: 'medium',
      thresholdName: 'Medium',
      factor: undefined,
      activityPercentage: undefined,
    },
    {
      id: 3,
      threshold: undefined,
      thresholdType: 'low',
      thresholdName: 'Low',
      factor: undefined,
      activityPercentage: undefined,
    },
  ],
  contentFactors: {
    id: 1,
    nonSoccerDiscount: undefined,
    highlightMultiplier: undefined,
    residualAtMaxAge: undefined,
    maxAgeAt: undefined,
    depreciationDelay: undefined,
  },
  platformImpact: [
    {
      id: 1,
      tierValue: 1,
      impact: undefined,
    },
    {
      id: 2,
      tierValue: 2,
      impact: undefined,
    },
    {
      id: 3,
      tierValue: 3,
      impact: undefined,
    },
    {
      id: 4,
      tierValue: 4,
      impact: undefined,
    },
  ],
  priceImpact: [
    {
      id: 1,
      attribute: 'content',
      impact: undefined,
    },
    {
      id: 2,
      attribute: 'activity',
      impact: undefined,
    },
    {
      id: 3,
      attribute: 'time',
      impact: undefined,
    },
    {
      id: 4,
      attribute: 'region',
      impact: undefined,
    },
    {
      id: 5,
      attribute: 'basis',
      impact: undefined,
    },
    {
      id: 5,
      attribute: 'upper_price',
      impact: undefined,
    },
    {
      id: 5,
      attribute: 'lower_price',
      impact: undefined,
    },
  ],
  initialCreditBalance: 0,
});

export enum CREDIT_PACKAGES_KEYS {
  CREDITS = 'credits',
  MULTIPLIER = 'multiplier',
  VIDEOS_COUNT = 'videosCount',
}

export enum OVERRIDES {
  MANUAL_OVERRIDE = 'manualOverride',
  PLATFORM_OVERRIDE = 'platformOverride',
}

export enum PRICING_ENGINE_SECTIONS {
  CONTENT = 'content',
  PLATFORM = 'platform',
  REGION = 'region',
  ACTIVITY = 'activity',
  TIME = 'time',
  OTHER = 'other',
}

export enum PRICING_ENGINE_ATTRIBUTES {
  NON_SOCCER_DISCOUNT = 'nonSoccerDiscount',
  HIGHLIGHT_MULTIPLIER = 'highlightMultiplier',
  RESIDUAL_AT_MAX_AGE = 'residualAtMaxAge',
  MAX_AGE_AT = 'maxAgeAt',
  DEPRECIATION_DELAY = 'depreciationDelay',
  REGION_FACTOR = 'regionFactor',
  ACTIVITY = 'activity',
  ACTIVITY_INDICATOR = 'activity-indicator',
  ACTIVITY_PERCENTAGE = 'activity-percentage',
  REGION_IMPACT = 'regionImpact',
  MAX_NEWS_VALUE = 'maxNewsValue',
  MIN_NEWS_VALUE = 'minNewsValue',
}

export enum PAGES {
  PRICING_ENGINE = '/pricing-engine',
  CREDITS_OVERRIDE = '/credits-override',
  CONTENT_OVERRIDE = '/content-override',
  CREDIT_PACKAGES = '/credit-packages',
  CREDIT_BALANCE_OVERRIDE = '/credit-balance-override',
  CONTENT_OWNERSHIP_OVERRIDE = '/content-ownership-override',
  SIGNUP_URL = '/signup-url',
  VETTING = '/vetting',
}

export const PAGE_TITLES_BY_PAGES = {
  [PAGES.PRICING_ENGINE]: 'Pricing Engine',
  [PAGES.CREDITS_OVERRIDE]: 'Credit Value Override',
  [PAGES.CONTENT_OVERRIDE]: 'Video Price Override',
  [PAGES.CREDIT_PACKAGES]: 'Credit Packages',
  [PAGES.CREDIT_BALANCE_OVERRIDE]: 'Add/Remove Credit',
  [PAGES.CONTENT_OWNERSHIP_OVERRIDE]: 'Video Access Removal',
  [PAGES.SIGNUP_URL]: 'Signup Link',
  [PAGES.VETTING]: 'User Vetting',
};

export enum ADMIN_SORT {
  NO_SORT = 'no_sort',
  LATEST_FIRST = 'asc',
  LATEST_LAST = 'desc',
}
