import styled from 'styled-components';

export const TRANSITION_DURATION_IN_S = 1;

export const Wrap = styled.div`
  left: 25vw;
  position: fixed;
  top: 4.5rem;
  width: 50vw;
  z-index: 9999;
  & > *:not(:last-child) {
    margin-bottom: 2px;
  }

  & > .removed-notification {
    transition: opacity ${TRANSITION_DURATION_IN_S}s;
    opacity: 0;
  }
`;

export const NotificationWrap = styled.span`
  display: block;
`;
