import { useHistory, useLocation } from 'react-router-dom';
import { PAGE_TITLES_BY_PAGES } from '../../constants/admin.constants';

const TabNavigation = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <div className='tabs-section d-flex align-items-start'>
      {Object.entries(PAGE_TITLES_BY_PAGES).map(([path, title]) => (
        <div
          className={location.pathname === path ? 'tab tab-active' : 'tab'}
          key={path}
          onClick={() => history.push(path)}>
          <span className='tab-title'>{title}</span>
        </div>
      ))}
    </div>
  );
};

export default TabNavigation;
