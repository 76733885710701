import React from 'react';

import useExistenceOfChanges from './useExistenceOfChanges';

const withExistenceOfChanges = <T extends { setHasChanged?: (hasChanged: boolean) => void }>(
  WrappedComponent: React.ComponentType<T>
) => {
  const ComponentWithExistenceOfChanges = (props: Omit<T, 'setHasChanged'>) => {
    const setHasChanged = useExistenceOfChanges();

    return <WrappedComponent {...(props as T)} setHasChanged={setHasChanged} />;
  };

  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  ComponentWithExistenceOfChanges.displayName = `withExistenceOfChanges(${displayName})`;

  return ComponentWithExistenceOfChanges;
};

export default withExistenceOfChanges;
