import React, { createContext, useState, useRef, useEffect } from 'react';
import { NotificationVariant, Notification } from '../playbook/Notification';

import * as S from './styles';

type Notification = {
  notificationVariant?: NotificationVariant;
  notificationMessage: string;
  disappearInSecs: number;
};

type InternalNotification = Notification & {
  removed?: boolean;
};

type Notifications = Map<number, InternalNotification>;

export interface INotificationContext {
  notify: (notificationVariant: NotificationVariant, notificationMessage: string) => void;
}

export const NotificationContext = createContext<INotificationContext>({} as INotificationContext);

const NotificationProvider = ({ children }: { children?: React.ReactNode }): JSX.Element => {
  const [notifications, setNotifications] = useState<Notifications>(new Map());
  const timeouts = useRef<Set<number>>(new Set());
  const counter = useRef(0);

  const removeTimeoutRef = (timeout: number) => {
    clearTimeout(timeout);
    timeouts.current.delete(timeout);
  };

  const addTimeoutRef = (timeout: number) => {
    timeouts.current.add(timeout);
  };

  useEffect(() => () => timeouts.current.forEach(clearTimeout), []);

  const notify = (
    notificationVariant: NotificationVariant,
    notificationMessage: string,
    disappearInSecs = 5
  ): void => {
    const id = counter.current++;

    const timeout = window.setTimeout(() => {
      removeNotificationFromScreen(id);
      removeTimeoutRef(timeout);
    }, disappearInSecs * 1000);

    addTimeoutRef(timeout);

    setNotifications((prevState) =>
      new Map(prevState).set(id, {
        notificationVariant,
        notificationMessage,
        disappearInSecs,
      })
    );
  };

  const removeNotificationFromMap = (id: number) => {
    setNotifications((prevNotifications) => {
      const updatedNotifications = new Map(prevNotifications);
      updatedNotifications.delete(id);

      return updatedNotifications;
    });
  };

  const removeNotificationFromScreen = (id: number) => {
    const timeout = window.setTimeout(() => {
      removeNotificationFromMap(id);
      removeTimeoutRef(timeout);
    }, S.TRANSITION_DURATION_IN_S * 1000 + 1);

    addTimeoutRef(timeout);

    setNotifications((prevNotifications) => {
      const notification = prevNotifications.get(id);
      if (notification) {
        return new Map(prevNotifications).set(id, {
          ...notification,
          removed: true,
        });
      }

      return prevNotifications;
    });
  };

  return (
    <NotificationContext.Provider value={{ notify }}>
      <S.Wrap>
        {!!notifications.size &&
          [...notifications.entries()].reverse().map(([id, notification]) => (
            <S.NotificationWrap
              key={id}
              className={notification.removed ? 'removed-notification' : ''}>
              <Notification
                variant={notification.notificationVariant}
                iconOnClick={() => removeNotificationFromScreen(id)}>
                {notification.notificationMessage}
              </Notification>
            </S.NotificationWrap>
          ))}
      </S.Wrap>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
