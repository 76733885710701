import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import staticAssets from '../../constants/staticAsset.constants';

type Props = {
  locked: boolean;
  sectionTitle: string;
  toggleLock: () => void;
};

const SectionWithLock = ({ sectionTitle, locked, toggleLock }: Props) => {
  return (
    <div className='d-flex flex-column align-items-start mt-4 mb-2'>
      <div className='warning-section d-flex align-items-center'>
        <img src={staticAssets.WARNING_RED} alt='warning-red' />
        <div className='warning-text ml-2'>
          <p className='main-text mb-0'>
            Only members of the Product Team should be making adjustments to Credit Packages
          </p>
          <span className='sub-text'>
            Please click the lock icon to make changes, and click “Apply” to finalize your edits.
          </span>
        </div>
      </div>
      <div className='unlock-text ml-2 mt-2'>
        {locked ? (
          <span className='spaced-text'>
            <span className='lock'>
              <FontAwesomeIcon icon={faLock} onClick={toggleLock} />
            </span>
            Unlock {sectionTitle}
          </span>
        ) : (
          <span className='spaced-text'>
            <span className='lock'>
              <FontAwesomeIcon icon={faLockOpen} onClick={toggleLock} />
            </span>
            Lock {sectionTitle}. If you made changes, click “Apply” button to save and lock
          </span>
        )}
      </div>
    </div>
  );
};

export default SectionWithLock;
