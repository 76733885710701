export const getStaticAsset = (bucketUrl: string) => ({
  DOWN_ARROW_BLACK: `${bucketUrl}/Arrow/Down/arrow_down_black.svg`,
  DOWN_ARROW_WHITE: `${bucketUrl}/Arrow/Down/arrow_down_white.svg`,

  UP_ARROW_BLACK: `${bucketUrl}/Arrow/Up/arrow_up_black.svg`,
  UP_ARROW_WHITE: `${bucketUrl}/Arrow/Up/arrow_up_white.svg`,

  RIGHT_ARROW_BLACK: `${bucketUrl}/Arrow/Right/arrow_right_black.svg`,
  RIGHT_ARROW_WHITE: `${bucketUrl}/Arrow/Right/arrow_right_white.svg`,

  DOWN_CHEV_BLACK: `${bucketUrl}/Chevron/Down/chevron_down_black.svg`,
  DOWN_CHEV_WHITE: `${bucketUrl}/Chevron/Down/chevron_down_white.svg`,

  UP_CHEV_BLACK: `${bucketUrl}/Chevron/Up/chevron_up_black.svg`,
  UP_CHEV_WHITE: `${bucketUrl}/Chevron/Up/chevron_up_white.svg`,

  RIGHT_CHEV_BLACK: `${bucketUrl}/Chevron/Right/chevron_right_black.svg`,
  RIGHT_CHEV_WHITE: `${bucketUrl}/Chevron/Right/chevron_right_white.svg`,

  LEFT_CHEV_BLACK: `${bucketUrl}/Chevron/Left/chevron_left_black.svg`,
  LEFT_CHEV_WHITE: `${bucketUrl}/Chevron/Left/chevron_left_white.svg`,

  CLOCK_BLACK: `${bucketUrl}/Clock/clock_black.svg`,
  CLOCK_WHITE: `${bucketUrl}/Clock/clock_white.svg`,

  OVERFLOW_BLACK: `${bucketUrl}/Overflow/overflow_black.svg`,
  OVERFLOW_WHITE: `${bucketUrl}/Overflow/overflow_white.svg`,

  INFO_BLACK: `${bucketUrl}/Info/info_black.svg`,
  INFO_WHITE: `${bucketUrl}/Info/info_white.svg`,

  DOWNLOAD_BLACK: `${bucketUrl}/Download/download_black.svg`,
  DOWNLOAD_WHITE: `${bucketUrl}/Download/download_white.svg`,

  EDIT_WHITE: `${bucketUrl}/Edit/edit_white.svg`,
  EDIT_BLACK: `${bucketUrl}/Edit/edit_black.svg`,
  EDIT_VIDEO_WHITE: `${bucketUrl}/Edit/edit_video_white.svg`,

  PBXP_LOGO: `${bucketUrl}/Logo/PressBox_Video_White_sm.png`,
  PBXP_LOGO_HORIZONTAL: `${bucketUrl}/Logo/StatsPerform_Logo_Secondary.svg`,

  CLOSE_BLACK: `${bucketUrl}/X/x_black.svg`,
  CLOSE_WHITE: `${bucketUrl}/X/x_white.svg`,

  PLUS: `${bucketUrl}/Plus/plus_black.svg`,
  MINUS: `${bucketUrl}/Minus/minus_black.svg`,

  EMPTY_HEART: `${bucketUrl}/Heart/empty_heart.svg`,
  FILL_HEART: `${bucketUrl}/Heart/filled_heart.svg`,
  EMPTY_HEART_WHITE: `${bucketUrl}/Heart/white_empty_heart.svg`,
  FILL_HEART_WHITE: `${bucketUrl}/Heart/white_filled_heart.svg`,

  TWITTER: `${bucketUrl}/Twitter/twitter.svg`,
  FACEBOOK: `${bucketUrl}/Facebook/facebook.svg`,
  INSTAGRAM: `${bucketUrl}/Instagram/instagram.svg`,
  TIKTOK: `${bucketUrl}/TikTok/tiktok.svg`,
  YOUTUBE: `${bucketUrl}/Youtube/youtube.svg`,

  TICK: `${bucketUrl}/Check/check_green.svg`,
  CANCEL: `${bucketUrl}/X/x_red.svg`,

  GLOBE_WHITE: `${bucketUrl}/Globe/globe_white.svg`,
  WARNING_RED: `${bucketUrl}/Warning/warning.png`,
  SEARCH_BLACK: `${bucketUrl}/Search/search_black.svg`,
  PENDO_BACKGROUND: `${bucketUrl}/Backgrounds/pendo_banner_visual.svg`,
});

export type StaticAssets = ReturnType<typeof getStaticAsset>;
