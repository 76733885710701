import React from 'react';
import useReduxState from '../hooks/useReduxState';

const withUserLanguage = <T extends { userLanguage?: string }>(
  WrappedComponent: React.ComponentType<T>
) => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithUserLanguage = (props: Omit<T, 'userLanguage'>) => {
    const userLanguage = useReduxState((store) => store.auth.user?.user?.user_metadata?.language);

    return <WrappedComponent {...(props as T)} userLanguage={userLanguage} />;
  };

  ComponentWithUserLanguage.displayName = `withUserLanguage(${displayName})`;

  return ComponentWithUserLanguage;
};

export default withUserLanguage;
