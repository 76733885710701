import 'bootstrap/dist/css/bootstrap.css';
import 'antd/dist/antd.css';
import './app/app.scss';
import 'react-dropdown/style.css';

import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';

import App from './app/app';

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root') as HTMLElement
);
