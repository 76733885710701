import React from 'react';
import { Locks, ToggleLock } from './SectionLockProvider';

import useSectionLock from './hooks/useSectionLock';

const withSectionLock = <T extends { locks?: Locks; toggleLock?: ToggleLock }>(
  WrappedComponent: React.ComponentType<T>
) => {
  const ComponentWithSectionLock = (props: Omit<T, 'locks' | 'toggleLock'>) => {
    const useSectionProps = useSectionLock();

    return <WrappedComponent {...(props as T)} {...useSectionProps} />;
  };

  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  ComponentWithSectionLock.displayName = `withSectionLock(${displayName})`;

  return ComponentWithSectionLock;
};

export default withSectionLock;
