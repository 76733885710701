import { PAGES, PAGE_TITLES_BY_PAGES } from '../../constants/admin.constants';
import staticAssets from '../../constants/staticAsset.constants';

type Props = {
  currentPath: PAGES;
};

const ReadOnly = ({ currentPath }: Props) => {
  return (
    <div className='d-flex flex-column align-items-start mt-4 mb-2'>
      <div className='warning-section d-flex align-items-center'>
        <img src={staticAssets.WARNING_RED} alt='warning-red' />
        <div className='warning-text ml-2'>
          <p className='main-text mb-0'>
            You don't have permission to make changes in {PAGE_TITLES_BY_PAGES[currentPath]}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReadOnly;
