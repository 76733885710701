export enum LanguageDictionary {
  en = 'English',
  de = 'German',
  fr = 'French',
  it = 'Italian',
  es = 'Spanish',
}

export enum LanguageIsoCodes {
  en = 'en-US',
  de = 'de-DE',
  fr = 'fr-FR',
  it = 'it-IT',
  es = 'es-ES',
}

export enum LanguageNameByIsoCodes {
  'en-US' = 'English',
  'de-DE' = 'German',
  'fr-FR' = 'French',
  'it-IT' = 'Italian',
  'es-ES' = 'Spanish',
}
