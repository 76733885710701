import styled from 'styled-components';

export const Button = styled.button`
  border: none;
  color: white;
  background-color: #000000;
  text-transform: capitalize;
  font-size: 0.875rem;
  padding: 8px;

  &:not(:last-child) {
    margin: 0 1rem 0 0;
  }
`;
