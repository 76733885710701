// eslint-disable-next-line
//@ts-ignore
import { ButtonProps } from '@as/playbook-components';

import * as S from './styles';

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  ButtonProps & { transparent?: boolean };

const Button = ({ transparent, ...rest }: Props): JSX.Element => {
  return <S.Button $transparent={transparent} {...rest}></S.Button>;
};

export default Button;
