import i18n from 'i18next';
import { TranslatableKeys } from './interfaces';
import { LanguageIsoCodes } from './constants';

import englishLang from '../assets/base-translations.json';
import frenchLang from '../assets/french-translations.json';
import germanLang from '../assets/german-translations.json';
import italianLang from '../assets/italian-translations.json';
import spanishLang from '../assets/spanish-translations.json';

const namespace = 'translations';
const defaultLanguage = LanguageIsoCodes.en;
const translations: Record<
  LanguageIsoCodes,
  {
    translations: TranslatableKeys;
  }
> = {
  [LanguageIsoCodes.en]: { [namespace]: englishLang },
  [LanguageIsoCodes.fr]: { [namespace]: frenchLang },
  [LanguageIsoCodes.de]: { [namespace]: germanLang },
  [LanguageIsoCodes.it]: { [namespace]: italianLang },
  [LanguageIsoCodes.es]: { [namespace]: spanishLang },
};

export const i18nInstance = i18n.createInstance(
  {
    interpolation: { escapeValue: false, prefix: '{', suffix: '}' },
    defaultNS: namespace,
    lng: defaultLanguage,
    resources: translations,
    react: {
      useSuspense: false,
    },
  },
  (err) => err && console.log(err)
);
