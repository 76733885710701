import { TFunction } from 'react-i18next';
import { TranslatableKeys } from '@pressbox-video/ui-translations/interfaces';
import { MinMax } from '@pressbox-video/service/elastic/elastic.interface';

export const PAGE_SIZE = 20;
export const SUPPORTED_SEARCH_LANGUAGES = ['en', 'de', 'fr', 'ar', 'it', 'es'];
export const SEARCH_LANGUAGES_STORAGE_KEY = 'search_languages';

export enum FILTER_VALUE {
  newest = 'latest',
  most_popular = 'popular',
  best_match = 'best',
}

export enum FILTER_SECTION_ID {
  SPORTS = 'sports',
  COMPETITION = 'competition',
  TYPE = 'type',
  ASPECT_RATIO = 'aspectRatio',
  LANGUAGE = 'language',
  VIDEO_LENGTH = 'videolength',
  CREDITS = 'credits',
  FORMAT = 'format',
}

const createFilterTranslations = <
  FilterTranslations extends Record<string, keyof TranslatableKeys>
>(
  opts: FilterTranslations
) => opts;

const FILTERS_TEXT_TRANSLATION_MAP = createFilterTranslations({
  newest: 'Newest',
  'most popular': 'MostPopular',
  'best match': 'BestMatch',
  'Press Conference': 'PressConference',
  News: 'News',
  Highlights: 'Highlights',
  Interviews: 'Interviews',
  'Data Analysis': 'DataAnalysis',
  Raw: 'Raw',
  Clean: 'Clean',
  Ready: 'Ready',
  'Subscribed Content Only': 'MySubscribedContentOnly',
  'Sort by': 'SortBy',
  Sport: 'Sport',
  Competition: 'Competition',
  'Video length': 'VideoLength',
  Language: 'Language',
  'Aspect ratio': 'AspectRatio',
  'Video format': 'VideoFormat',
  Credits: 'Credits',
  'Video type': 'VideoType',
  'Toggle on to show videos within your subscription': 'ToggleOnToShowVideosWithinYourSubscription',
});

type FilterTranslationText = keyof typeof FILTERS_TEXT_TRANSLATION_MAP;

export interface Filter {
  id: string;
  title?: FilterTranslationText;
  accordion: boolean;
  sections: Section[];
  open?: boolean;
}

export type IChecked = string[] | MinMax;

export interface Section {
  id: string;
  title?: string;
  searchable: boolean;
  checked: IChecked;
  type: FilterType;
  options: Option[];
}

export type FilterType = 'checkbox' | 'progressbar' | 'range' | 'toggle' | 'radiobutton';

export interface Option {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // values of option can be anything dependent on FilterType
}

export const translateFilter = (name: string, t: TFunction): string =>
  FILTERS_TEXT_TRANSLATION_MAP[name as unknown as keyof typeof FILTERS_TEXT_TRANSLATION_MAP]
    ? t(FILTERS_TEXT_TRANSLATION_MAP[name as unknown as keyof typeof FILTERS_TEXT_TRANSLATION_MAP])
    : name;

export class FilterDataTemplate {
  filterData: Filter[] = [
    {
      title: 'Sort by',
      id: 'sort_by',
      accordion: false,
      sections: [
        {
          id: 'match',
          searchable: false,
          type: 'radiobutton',
          checked: [],
          options: [
            {
              name: 'newest',
              id: 'newest',
            },
            {
              name: 'most popular',
              id: 'most_popular',
            },
            {
              name: 'best match',
              id: 'best_match',
            },
          ],
        },
      ],
    },
    {
      id: 'my_subscribed_content',
      accordion: false,
      sections: [
        {
          id: 'content',
          title: 'Subscribed Content Only',
          searchable: false,
          checked: [],
          type: 'toggle',
          options: [
            {
              name: 'Toggle on to show videos within your subscription',
            },
          ],
        },
      ],
    },
    {
      title: 'Sport',
      id: 'sports',
      accordion: true,
      open: true,
      sections: [
        {
          id: 'sports',
          searchable: true,
          type: 'checkbox',
          checked: [],
          options: [],
        },
      ],
    },
    {
      title: 'Competition',
      id: 'competition',
      accordion: true,
      open: true,
      sections: [
        {
          id: 'competition',
          searchable: true,
          type: 'checkbox',
          checked: [],
          options: [],
        },
      ],
    },
    {
      title: 'Video length',
      id: 'videolength',
      accordion: true,
      open: true,
      sections: [
        {
          id: 'videolength',
          searchable: false,
          type: 'range',
          checked: { min: 0, max: 0 },
          options: [
            {
              name: 'videolength',
              minValue: 0.0,
              maxValue: 3.0,
              step: 0.5,
              marks: {
                0.0: {
                  style: {
                    color: '#222222',
                  },
                  label: '0.00',
                },
                0.5: '',
                1.0: {
                  style: {
                    color: '#222222',
                  },
                  label: '1.00',
                },
                1.5: '',
                2.0: {
                  style: {
                    color: '#222222',
                  },
                  label: '2.00',
                },
                2.5: '',
                3.0: {
                  style: {
                    color: '#222222',
                  },
                  label: '3.00+',
                },
              },
            },
          ],
        },
      ],
    },
    {
      title: 'Language',
      id: 'language',
      accordion: true,
      open: true,
      sections: [
        {
          id: 'language',
          searchable: true,
          type: 'checkbox',
          checked: [],
          options: SUPPORTED_SEARCH_LANGUAGES.map((code) => ({ name: code })),
        },
      ],
    },
    {
      title: 'Aspect ratio',
      id: 'aspectRatio',
      accordion: true,
      open: true,
      sections: [
        {
          id: 'aspectRatio',
          searchable: false,
          type: 'checkbox',
          checked: [],
          options: [
            {
              name: '16:9',
            },
            {
              name: '9:16',
            },
            {
              name: '1:1',
            },
          ],
        },
      ],
    },
    {
      title: 'Video type',
      id: 'video_type',
      accordion: true,
      open: true,
      sections: [
        {
          id: 'type',
          searchable: false,
          type: 'checkbox',
          checked: [],
          options: [],
        },
      ],
    },
    {
      title: 'Video format',
      id: 'video_format',
      accordion: true,
      open: true,
      sections: [
        {
          id: 'format',
          searchable: false,
          type: 'checkbox',
          checked: [],
          options: [],
        },
      ],
    },
    {
      title: 'Credits',
      id: 'Credits',
      accordion: true,
      open: true,
      sections: [
        {
          id: 'credits',
          searchable: false,
          type: 'range',
          checked: { min: 0, max: 0 },
          options: [
            {
              name: 'credits',
              minValue: 0,
              maxValue: 600,
              step: 100,
              marks: {
                0: {
                  style: {
                    color: '#222222',
                  },
                  label: 0,
                },
                100: '',
                200: {
                  style: {
                    color: '#222222',
                  },
                  label: '200',
                },
                300: '',
                400: {
                  style: {
                    color: '#222222',
                  },
                  label: '400',
                },
                500: '',
                600: {
                  style: {
                    color: '#222222',
                  },
                  label: '600+',
                },
              },
            },
          ],
        },
      ],
    },
  ];
}
