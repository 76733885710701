import { PAGES } from '../../constants/admin.constants';
import { SECTIONS_WITH_LOCKS } from '../../constants/auth0.roles';
import useReadOnly from './hooks/useReadOnly';
import ReadOnly from './ReadOnly';
import SectionWithLock from './SectionWithLock';

type Props = {
  locked: boolean;
  sectionTitle: string;
  toggleLock: () => void;
  currentPath: PAGES;
};

const SectionLock = ({ sectionTitle, locked, toggleLock, currentPath }: Props) => {
  const isReadOnly = useReadOnly();

  return (
    <div>
      {isReadOnly ? (
        <ReadOnly currentPath={currentPath} />
      ) : (
        <div>
          {SECTIONS_WITH_LOCKS.includes(currentPath) && (
            <SectionWithLock
              sectionTitle={sectionTitle}
              locked={locked}
              toggleLock={toggleLock}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SectionLock;
