import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import loadable from '@loadable/component';
import { PAGES, PAGE_TITLES_BY_PAGES } from '../constants/admin.constants';
import TabNavigation from '../components/TabNavigation';
import withExistenceOfChanges from '../components/ExistenceOfChangesModal/withExistenceOfChanges';
import withSectionLock from '../components/SectionLock/withSectionLock';
import SectionLock from '../components/SectionLock';
import NavBar from '../components/NavBar/NavBar';
import useSectionLock from '../components/SectionLock/hooks/useSectionLock';

const PricingEngine = withExistenceOfChanges(
  withSectionLock(loadable(() => import('../components/pricing-engine/pricing-engine')))
);

const CreditsOverride = withExistenceOfChanges(
  withSectionLock(loadable(() => import('../components/credits-override/credits-override')))
);

const ContentOverride = withExistenceOfChanges(
  withSectionLock(loadable(() => import('../components/content-override/content-override')))
);

const CreditPackages = withExistenceOfChanges(
  withSectionLock(loadable(() => import('../components/credit-packages/credit-packages')))
);

const CreditBalanceOverride = withExistenceOfChanges(
  withSectionLock(
    loadable(() => import('../components/credit-balance-override/credit-balance-override'))
  )
);

const ContentOwnershipOverride = withExistenceOfChanges(
  withSectionLock(
    loadable(() => import('../components/content-ownership-override/content-ownership-override'))
  )
);

const SignupUrl = withSectionLock(loadable(() => import('../components/signup-url/signup-url')));

const UserVetting = loadable(() => import('../components/user-vetting'));

const Routes = () => {
  const { pathname } = useLocation();
  const { toggleLock, locks } = useSectionLock();
  const currentPath = pathname as PAGES;

  return (
    <Switch>
      <Redirect exact from='/' to={PAGES.PRICING_ENGINE} />
      <>
        <NavBar />
        <div className='admin-console-wrapper'>
          <div className='d-flex flex-column p-4'>
            <TabNavigation />
            <SectionLock
              toggleLock={() => toggleLock(currentPath)}
              locked={!!locks[currentPath]}
              sectionTitle={PAGE_TITLES_BY_PAGES[currentPath]}
              currentPath={currentPath}
            />
            <div
              className={
                pathname === PAGES.PRICING_ENGINE
                  ? 'tabs-wrapper transparent-background d-flex flex-column flex-grow-1 w-100'
                  : 'tabs-wrapper d-flex flex-column flex-grow-1 w-100'
              }>
              <div className='tab-content p-4'>
                <Route exact path={PAGES.PRICING_ENGINE} component={PricingEngine} />
                <Route exact path={PAGES.CREDITS_OVERRIDE} component={CreditsOverride} />
                <Route exact path={PAGES.CONTENT_OVERRIDE} component={ContentOverride} />
                <Route exact path={PAGES.CREDIT_PACKAGES} component={CreditPackages} />
                <Route
                  exact
                  path={PAGES.CREDIT_BALANCE_OVERRIDE}
                  component={CreditBalanceOverride}
                />
                <Route
                  exact
                  path={PAGES.CONTENT_OWNERSHIP_OVERRIDE}
                  component={ContentOwnershipOverride}
                />
                <Route exact path={PAGES.SIGNUP_URL} component={SignupUrl} />
                <Route exact path={PAGES.VETTING} component={UserVetting} />
              </div>
            </div>
          </div>
        </div>
      </>
    </Switch>
  );
};

export default Routes;
