import { Auth0ContextInterface } from '@auth0/auth0-react';
import {
  IAuth0User,
  IPBVUser,
  IPressboxVideoAppMetadata,
} from '@pressbox-video/ui-interfaces/user.interface';

import { APIService } from './api.service';

export class UserService {
  static getUserByEmail(email: string): Promise<APIServiceResponse<IPBVUser>> {
    return APIService.get(`/user/by-email?email=${encodeURIComponent(email)}`);
  }

  static async savePbvAppMetadata(
    auth0: Auth0ContextInterface,
    userId: string,
    pbvAppMetadata: IPressboxVideoAppMetadata
  ): Promise<APIServiceResponse<{ user: IAuth0User }>> {
    const result = await APIService.post<{ user: IAuth0User }>(`/user/metadata`, {
      userId,
      appMetadata: { pressBoxVideo: pbvAppMetadata },
    });

    await auth0.getAccessTokenSilently({
      ignoreCache: true,
    });

    return result;
  }

  static getSignupToken = async (): Promise<APIServiceResponse<string>> =>
    APIService.get(`/user/signup-token`);
}
