import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

import { PAGES } from '../../constants/admin.constants';
import staticAssets from '../../constants/staticAsset.constants';
import NavBarButton from '../NavBarButton';

import * as S from './styles';

const NavBar = () => {
  const { logout, user } = useAuth0();
  const history = useHistory();

  return (
    <div className='nav-flex-wrapper d-flex flex-column align-items-center justify-content-center'>
      <div className='w-100 px-4 m-0 d-flex align-items-center justify-content-between nav-bar-wrapper flex-grow-0'>
        <img
          className='press-box logo'
          src={staticAssets.PBXP_LOGO}
          alt='PressBox Logo'
          onClick={() => history.push(PAGES.PRICING_ENGINE)}
        />
        <div>
          {user?.email && <S.Email>(Signed in as {user.email})</S.Email>}
          <NavBarButton
            text='Sign out'
            icon={<S.ExitIcon />}
            onClick={() => logout({ returnTo: `${window.location.origin}/sign-out` })}
          />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
