import { i18nInstance } from '../i18next';

export const getBrowserLocale = (): string =>
  navigator.languages?.[0] ? navigator.languages[0] : navigator.language;

export const changeAppLanguage = async (userLanguage?: string): Promise<void> => {
  if (userLanguage && userLanguage !== i18nInstance.language) {
    await i18nInstance.changeLanguage(userLanguage);
  }
};
