import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { PAGES } from '../../../constants/admin.constants';
import {
  GLOBAL_ACTIVATION_ROLE_ACCESS,
  NEWS_ROLE_ACCESS,
  ROLES,
  ROLES_URL
} from '../../../constants/auth0.roles';

const useReadOnly = (): boolean => {
  const { user } = useAuth0();
  const { pathname } = useLocation();
  const currentPath = pathname as PAGES;
  const roles = user?.[ROLES_URL] || [ROLES.READ_ONLY];

  return !(roles.includes(ROLES.SUPERUSER) ||
    (roles.includes(ROLES.NEWS) && NEWS_ROLE_ACCESS.includes(currentPath)) ||
    (roles.includes(ROLES.GLOBAL_ACTIVATION) && GLOBAL_ACTIVATION_ROLE_ACCESS.includes(currentPath)));
};

export default useReadOnly;
