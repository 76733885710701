import React from 'react';
/*Default loader will overlay full-screen
     Sizes:
        full-screen: will take 100% of the screen
        fit: will take 100% of parent;
        small: parent needs to be non-static. will appear at the center of parent div.

*/

interface Props {
  size?: string;
  background?: string;
}
export default class Loader extends React.Component<Props> {
  override render(): JSX.Element {
    const type = this.props.size ? this.props.size : 'full-screen';
    const background = this.props.background ? { backgroundColor: `${this.props.background}` } : {};

    return (
      <div className={`loader-container ${type}`} style={background}>
        <div className='loader'>
          <div className='tile1'></div>
          <div className='tile2'></div>
          <div className='tile3'></div>
        </div>
      </div>
    );
  }
}
